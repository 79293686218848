<template>
  <UiDropdown
    v-if="languageAvailable.length > 0 && options.length > 0"
    id="langSwitcher"
    class="lang form-dropdown"
    :options="options"
    :value="currentUrl"
    :css="styles"
    @input="onChange"
  >
    <!-- slot option + label -->
  </UiDropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

// import { useRouter } from 'vue-router'

import UiDropdown from '@/components/ui/Dropdown.vue'
import { getLanguageAvailable } from '@/config/languages'
import { useCountry } from '@/plugins/country'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'

import styles from './lang.module.scss'

import type { Language } from '@/types'

const chrome = useChromeStore()
const country = useCountry()
const resource = useResourceStore()

const languageAvailable = getLanguageAvailable(country)

const options = computed(() => {
  if (resource.languages) {
    return Object.keys(resource.languages).map(lang => {
      const value = resource.languages[lang as Language]

      return {
        label: lang,
        value,
      }
    })
  }

  return []
})

const currentUrl = computed(() => resource.languages[chrome.language])

const onChange = (url: string) => {
  if (url !== currentUrl.value) {
    // router.push(url)
    window.location.href = url
  }
}
</script>

<style lang="scss" scoped>
$bp: l;

.lang {
  --dd-icon-width: 1.2rem;
  --dd-padding-v: 1rem;
  --dd-padding-h: 3rem;
  --dd-c-border: #{color.mix($c-grey, $c-white, 20%)};

  &.is-expanded,
  &:has([aria-expanded='true']) {
    z-index: $header-z-index;
  }

  :deep(.select) {
    min-width: 0;
    margin-top: 0;
  }

  :deep(.trigger) {
    @extend %text-uppercase;

    gap: 0.6rem;
    padding: 0.9rem 2.2rem;
  }

  :deep(.list) {
    top: -0.5rem;
    bottom: initial;
    border: 0.1rem solid var(--dd-c-border);
    border-radius: 2rem;
    box-shadow: 0 0.4 2 0 rgba(0, 0, 0, 10%);
    transform: translateY(-100%);
  }

  :deep(.option) {
    @extend %text-center;
    @extend %text-uppercase;

    color: color.mix($c-grey, $c-white, 40%);

    &:first-child {
      padding-top: var(--dd-padding-v);
    }

    &.is-selected {
      color: var(--c-text);
      background-color: var(--dd-c-default);
    }

    &:hover,
    &:focus {
      color: var(--c-text);
      background-color: var(--dd-c-default);
    }
  }

  @include mq($bp) {
    :deep(.trigger) {
      color: var(--c-text);
      border: 0;
    }

    :deep(.list) {
      top: initial;
      bottom: -0.5rem;
      left: 50%;
      width: fit-content;
      min-width: 100%;
      transform: translate(-50%, 100%);
    }

    :deep(.option) {
      padding: 1rem;
    }
  }
}
</style>
