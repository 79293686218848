/* eslint-disable quotes */
/*
 ** These scripts will be triggered on the contact page when navigating
 ** from specific pages like solutions and home
 */
export const popups: Record<
  'fr' | 'nl',
  Record<string, Record<string, Record<'mobile' | 'desktop', string>>>
> = {
  fr: {
    employeur: {
      'edenred-cadeaux': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30027', '18']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30027', '14']);`,
      },
      'edenred-eco': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30026', '18']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30026', '14']);`,
      },
      'edenred-repas': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30025', '18']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30025', '14']);`,
      },
      'edenred-sport-culture': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30028', '18']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30028', '14']);`,
      },
    },
    commercant: {
      'edenred-cadeaux': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000048', '36']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000048', '34']);`,
      },
      'edenred-eco': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000047', '36']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000047', '34']);`,
      },
      'edenred-repas': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000046', '36']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000046', '34']);`,
      },
      'edenred-sport-culture': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000049', '34']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000049', '36']);`,
      },
    },
  },
  nl: {
    werkgevers: {
      'edenred-geschenk': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30033', '22']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30033', '15']);`,
      },
      'edenred-eco': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30032', '22']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30032', '15']);`,
      },
      'edenred-maaltijd': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30031', '22']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30031', '15']);`,
      },
      'edenred-sport-cultuur': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30034', '22']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '30034', '15']);`,
      },
    },
    handelaars: {
      'edenred-geschenk': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000053', '37']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000053', '35']);`,
      },
      'edenred-eco': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000052', '37']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000052', '35']);`,
      },
      'edenred-maaltijd': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000051', '35']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000051', '37']);`,
      },
      'edenred-sport-cultuur': {
        mobile: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000054', '37']);`,
        desktop: `window._nm = window._nm || []; window._nm.push(['manualTrigger', '300000054', '35']);`,
      },
    },
  },
}

export const getPopup = (
  lang: 'fr' | 'nl',
  profile?: string,
  solution?: string
) => {
  const type = window.innerWidth < 1024 ? 'mobile' : 'desktop'

  if (profile && solution) {
    return popups[lang][profile]?.[solution]?.[type] || undefined
  } else if (lang === 'nl') {
    return popups.nl.handelaars['edenred-maaltijd'][type]
  }

  return popups.fr.employeur['edenred-repas'][type]
}
