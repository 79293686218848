export const loadFonts = () => {
  const spaceMono = new FontFace(
    'SpaceMono',
    'url(/fonts/Spacemono-Regular.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )
  const martelRegular = new FontFace(
    'Martel',
    'url(/fonts/Martel-Regular.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )
  const martelBold = new FontFace('Martel', 'url(/fonts/Martel-Bold.woff2)', {
    style: 'normal',
    weight: '700',
  })
  // const swiss721Thin = new FontFace(
  //   'Swiss721BT',
  //   'url(/fonts/Swiss721BT-Thin.woff2)',
  //   {
  //     style: 'normal',
  //     weight: '200',
  //   }
  // )
  const swiss721Light = new FontFace(
    'Swiss721BT',
    'url(/fonts/Swiss721BT-Light.woff2)',
    {
      style: 'normal',
      weight: '300',
    }
  )
  const swiss721Roman = new FontFace(
    'Swiss721BT',
    'url(/fonts/Swiss721BT-Roman.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )
  // const swiss721Medium = new FontFace(
  //   'Swiss721BT',
  //   'url(/fonts/Swiss721BT-Medium.woff2)',
  //   {
  //     style: 'normal',
  //     weight: '600',
  //   }
  // )
  const swiss721Bold = new FontFace(
    'Swiss721BT',
    'url(/fonts/Swiss721BT-Bold.woff2)',
    {
      style: 'normal',
      weight: '700',
    }
  )

  const edenredRegular = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Regular.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )
  const edenredLight = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Light.woff2)',
    {
      style: 'normal',
      weight: '300',
    }
  )
  const edenredLightItalic = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Light-Italic.woff2)',
    {
      style: 'italic',
      weight: '300',
    }
  )
  const edenredMedium = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Medium.woff2)',
    {
      style: 'normal',
      weight: '500',
    }
  )
  const edenredBold = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Bold.woff2)',
    {
      style: 'normal',
      weight: '700',
    }
  )
  const edenredBlack = new FontFace(
    'Edenred',
    'url(/fonts/Edenred-Black.woff2)',
    {
      style: 'normal',
      weight: '900',
    }
  )

  // This only generates a small glitch (portfolio, set item width)
  // solved by adding <link rel="preload"…
  document.fonts.add(spaceMono)
  document.fonts.add(martelRegular)
  document.fonts.add(martelBold)
  // document.fonts.add(swiss721Thin)
  document.fonts.add(swiss721Light)
  document.fonts.add(swiss721Roman)
  // document.fonts.add(swiss721Medium)
  document.fonts.add(swiss721Bold)
  document.fonts.add(edenredRegular)
  document.fonts.add(edenredLight)
  document.fonts.add(edenredLightItalic)
  document.fonts.add(edenredMedium)
  document.fonts.add(edenredBold)
  document.fonts.add(edenredBlack)

  return document.fonts.ready
}
