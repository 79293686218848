import type { Country } from '@/types'

/**
 * Countries
 */
const countriesDefault: Country = 'be'
const countriesAvailable = ['be', 'lu'] as const

const getCountryFromHostname = (hostname: string) => {
  let c = process.env.EDENRED_COUNTRY

  if (!c) {
    // prod: 'edenred.be'
    // staging: 'edenred-website-v2-be-app.staging.epic-sys.io'
    // dev (browser): 'localhost'
    // dev (server): 'undefined'
    c = hostname.includes('staging.epic-sys.io')
      ? hostname.split('.')[0].split('-').slice(0, -1).pop()
      : hostname.split('.').pop()
  }

  const country: Country =
    c && countriesAvailable.includes(c as Country)
      ? (c as Country)
      : countriesDefault

  return country
}

export { countriesAvailable, getCountryFromHostname }
