<template>
  <div class="html-text-outer">
    <component :is="component"></component>
  </div>
</template>

<script setup lang="ts">
import { compile } from 'vue'

import type { PropType } from 'vue'

interface HTMLText {
  htmltext: string
}

const props = defineProps({
  content: {
    type: [Object, String] as PropType<HTMLText | String>,
    required: true,
  },
})

const component = compile(
  `<div class="html-text wysiwyg">${
    (props.content as HTMLText).htmltext || props.content
  }</div>`
)
</script>

<style lang="scss" scoped>
:deep(p) {
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

:deep() {
  .action,
  [class*='action--'] {
    margin-right: 1rem;

    &[class*='--white'] {
      color: $c-black;
      border: 3px solid $c-black;
    }
  }
}
</style>
