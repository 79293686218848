<template>
  <ul class="nav-secondary">
    <li
      v-for="(item, index) in content"
      :key="index"
      class="nav-secondary__item"
    >
      <router-link
        v-if="isRelative(item.url)"
        class="nav-secondary__item__link"
        :to="item.url"
      >
        {{ item.label }}
      </router-link>

      <a v-else class="nav-secondary__item__link" :href="item.url">
        {{ item.label }}
      </a>
    </li>
    <li class="nav-secondary__item">
      <ChromeLang
        class="nav-secondary__item__link nav-secondary__item__switcher"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import ChromeLang from '@/components/chrome/Lang.vue'

import type { PropType } from 'vue'

export interface NavSecondary {
  url: string
  label: string
}

defineProps({
  content: {
    type: Object as PropType<NavSecondary[]>,
    required: true,
    default: () => ({}),
  },
  baseClass: {
    type: String,
    default: '',
  },
})

const isRelative = (item: string) => item.startsWith('/')
</script>

<style lang="scss" scoped>
$bp: l;

.nav-secondary {
  display: block;
  list-style: none;
  margin: 0;
  padding: 6rem 2.5rem 0;

  @include mq($bp) {
    padding: 0;
    text-align: right;
  }
}

.nav-secondary__item {
  @include mq($bp) {
    display: inline-block;
  }
}

.nav-secondary__item__link {
  @extend %fw-normal;

  position: relative;
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  color: var(--c-text);

  &.router-link-exact-active {
    color: $c-red;

    &::after {
      background: $c-red;
    }
  }

  @include mq($bp) {
    position: relative;
    display: inline-flex;
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.08rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0.5rem;
      left: 1rem;
      width: calc(100% - 2rem);
      height: 0.2rem;
      background: $c-black;
      transition: transform 0.2s ease-in-out;
      transform-origin: left center;
      transform: scaleX(0);
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &.router-link-exact-active {
      &::after {
        background: $c-red;
      }
    }
  }

  @include mq(xl) {
    padding: 1rem 2rem;

    &::after {
      left: 2rem;
      width: calc(100% - 4rem);
    }
  }
}

.nav-secondary__item__switcher {
  position: fixed;
  z-index: $header-z-index;
  top: calc(calc(var(--vh) * 100) - 1rem);
  right: 2.5rem;
  margin: 0;
  padding: 0;
  transform: translateY(-100%);

  @include mq($bp) {
    position: relative;
    top: initial;
    right: initial;
    margin-right: -1rem;
    transform: none;
  }
}
</style>
