import type { Directive } from 'vue'

const defaultSets = [
  '160',
  '240',
  '320',
  '480',
  '640',
  '960',
  '1280',
  '1440',
  '1600',
  '1920',
  '2240',
  '2560',
  '2880',
] as const

type SetSize = (typeof defaultSets)[number]
type Sets = Record<SetSize, string>
interface VueSrcData {
  src: string
  width: string
  height: string
  rawUrl: string
  sets: Sets
  alt: string
  caption: string
  description: string
}

const directive: Directive<HTMLImageElement, VueSrcData> = {
  created(el, binding, vnode) {
    if (el.hasAttribute('src')) {
      return
    }

    const { src, sets, width, height } = binding.value

    el.setAttribute('src', src)
    el.setAttribute('width', width)
    el.setAttribute('height', height)

    if (!sets) {
      return
    }

    const customSets = (vnode.props as { sets?: SetSize[] }).sets || defaultSets

    let srcset = ''

    customSets?.forEach(size => {
      const url = sets[size]

      if (url) {
        srcset += `${url} ${size}w,`
      }
    })

    srcset = srcset.replace(/,$/, '')

    el.setAttribute('srcset', srcset)
  },
}

export default directive
