import { inject } from 'vue'

import { countriesAvailable, getCountryFromHostname } from '@/config/countries'

import type { App, InjectionKey } from 'vue'
import type { Country } from '@/types'

const COUNTRY_SYMBOL = Symbol('countryKey') as InjectionKey<Country>

export function createCountry(countryOrHostname: string) {
  const country = countriesAvailable.includes(countryOrHostname as Country)
    ? (countryOrHostname as Country)
    : getCountryFromHostname(countryOrHostname)

  return {
    install(app: App) {
      app.provide<Country>(COUNTRY_SYMBOL, country)
    },
  }
}

export function useCountry() {
  const country = inject(COUNTRY_SYMBOL) as Country | undefined

  if (!country) {
    throw new Error('useCountry must be used within a provider.')
  }

  return country
}
