<template>
  <component
    :is="component"
    :class="useModifiers('action', modifiers)"
    :click="clickHandler"
    :enter="enterHandler"
    :leave="leaveHandler"
    :url="url"
    :to="to"
    :target="target"
    v-bind="$attrs"
  >
    <!-- eslint-disable-next-line vue/no-v-html-->
    <span v-if="label" class="action__label" v-html="label"></span>
    <span v-if="icon.name" class="action__icon-wrapper">
      <SvgSprite
        v-if="icon.name"
        class="action__icon"
        :symbol="icon.name"
        :size="icon.viewbox"
        role="img"
        aria-label="enable"
      />
    </span>
    <slot />
  </component>
</template>

<script lang="tsx">
/* eslint-disable vue/one-component-per-file */

import { defineComponent, h } from 'vue'
import { useRoute } from 'vue-router'

import { iconRegistered, iconViewbox } from '@/config/app'
import { useResourceStore } from '@/stores/resource'
import { useModifiers } from '@/utils/filters'
import { push } from '@/utils/tracking'

import type { PropType } from 'vue'
import type { Icon } from '@/types'

type ActionTarget = '_blank'
type ActionTag = 'a' | 'button'
type ActionModifiers = string[] // 'arrow' | 'reverse' | 'small' | 'large' | …

interface ActionContent {
  label?: string
  title?: string
  url?: string
  target?: ActionTarget
  tag?: ActionTag
  modifiers?: ActionModifiers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: null | Record<string, any>
  icon?: string | Icon
  iconsup: string
}

// Render functions
const props = {
  url: { type: String },
  to: { type: Object },
  target: { type: String },
  click: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
  enter: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
  leave: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
}

const ActionAnchor = defineComponent({
  name: 'ActionAnchor',
  props,
  setup: (props, ctx) => () =>
    h(
      <a href={props.url} onMouseenter={props.enter} onMouseleave={props.leave}>
        {ctx.slots.default?.()}
      </a>
    ),
})

const ActionOutside = defineComponent({
  name: 'ActionOutside',
  props,
  setup: (props, ctx) => () =>
    h(
      <a
        href={props.url}
        target={props.target}
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
        rel="noopener noreferrer"
      >
        {ctx.slots.default?.()}
      </a>
    ),
})

const ActionRouterLink = defineComponent({
  name: 'ActionRouterLink',
  props,
  setup: (props, ctx) => () =>
    h(
      <router-link
        to={props.url || props.to}
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default?.()}
      </router-link>
    ),
})

const ActionButton = defineComponent({
  name: 'ActionButton',
  props,
  setup: (props, ctx) => () =>
    h(
      <button
        type="button"
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default?.()}
      </button>
    ),
})

export default defineComponent({
  name: 'Action',
  props: {
    content: {
      type: Object as () => ActionContent,
      required: true,
      validator: (value: ActionContent) => {
        if (!value.to && (!value.tag || value.tag === 'a')) {
          !value.url &&
            console.warn('[GAction] url property is mandatory for links')

          return value.url !== undefined
        }

        return true
      },
    },
  },
  emits: ['on-click', 'on-enter', 'on-leave'],
  setup(props, ctx) {
    const route = useRoute()
    const resource = useResourceStore()
    const clickHandler = (e: MouseEvent) => {
      ctx.emit('on-click', e)

      // Tracking
      const target = e.currentTarget || e.target

      if (
        (target as HTMLAnchorElement).href.match(/\.pdf$/) &&
        ['solution', 'solution-lu'].includes(resource.template)
      ) {
        // Pdf links on solution pages
        push({
          eventTmp: 'purchaseOrder',
          eventCategory: 'purchaseOrder',
          eventAction: 'orderFrom',
          eventLabel: route.params.page as string,
        })
      } else if ((target as HTMLAnchorElement).target === '_blank') {
        // External links
        push({
          eventTmp: 'outboundLink',
          eventCategory: 'outboundLink',
          eventAction: window.location.hostname,
          eventLabel: (target as HTMLAnchorElement).href,
        })
      }
    }
    const enterHandler = (e: MouseEvent) => ctx.emit('on-enter', e)
    const leaveHandler = (e: MouseEvent) => ctx.emit('on-leave', e)
    // REVIEW: is reactivity needed?
    // eslint-disable-next-line vue/no-setup-props-destructure
    const {
      url = '',
      to = null,
      tag,
      target = '',
      icon: iconData,
      modifiers = [],
    } = props.content
    const label = props.content.label || props.content.title
    const isButton = tag === 'button'
    const isAnchor = to === null && url && /^(http|#)/.test(url)
    const isOutside = target === '_blank'

    if (!isButton) {
      modifiers.push('link')
    }

    let component = ActionRouterLink
    if (isButton) {
      modifiers.push('btn')
      component = ActionButton
    } else if (isOutside) {
      component = ActionOutside
    } else if (isAnchor) {
      component = ActionAnchor
    }

    const icon: Icon = {} as Icon
    if (iconData) {
      modifiers.push('icon')

      if (typeof iconData === 'string' && iconRegistered[iconData]) {
        modifiers.push(iconData as string)
        icon.name = iconRegistered[iconData].symbol || ''
        icon.viewbox = iconRegistered[iconData].viewbox || iconViewbox
      } else {
        icon.name =
          (iconData as Icon).name ||
          iconRegistered[(iconData as Icon).slug || 'arrow']?.symbol
        icon.viewbox = (iconData as Icon).viewbox || iconViewbox
        modifiers.push(icon.name)
      }
    }

    return {
      useModifiers,
      clickHandler,
      enterHandler,
      leaveHandler,
      component,
      label,
      url,
      to: to || {},
      target,
      icon,
      modifiers,
    }
  },
})
</script>

<style lang="scss" scoped>
// Defaults
.action,
[class*='action--'] {
  color: $c-black;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: $c-black;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  @extend %fw-bold;
  @extend %h5;

  display: inline-block;
  padding: 0.9rem 2.5rem;
  color: $c-white;
  font-family: $ff-alt;
  white-space: nowrap;
  background: $c-main;
  border: 0;
  border-radius: 6rem;
  outline: 0.1rem solid $c-main;
  outline-offset: -0.1rem;
  transition:
    background-color 0.2s ease-in-out,
    outline-color 0.2s ease-in-out,
    box-shadow 0.2s,
    color 0.2s;

  &:disabled {
    opacity: 0.5;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $c-main;
    background: $c-white;
    outline-color: $c-white;
    box-shadow: 0 0.5rem 2rem rgba($c-black, 10%);

    svg {
      stroke: $c-white;
    }
  }

  @include mq(l) {
    padding: 1.3rem 3rem;
  }
}

[class*='action--'][class*='--black'] {
  color: $c-black;
  background-color: transparent;
  outline: 0.1rem solid $c-black;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $c-black;
    background: $c-white;
    outline-color: $c-white;

    svg {
      stroke: $c-black;
    }
  }

  // ?
  // @include mq($until: l) {
  //   /* stylelint-disable-next-line declaration-no-important */
  //   padding: 1rem !important;
  //   font-size: 1.5rem;
  // }
}

[class*='action--'][class*='--small'] {
  @include fluid(
    font-size,
    (
      xxs: 1.3rem,
      xxl: 1.5rem,
    )
  );
  @include fluid(
    line-height,
    (
      xxs: 2rem,
      xxl: 2.4rem,
    )
  );

  padding: 0.7rem 2rem;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @include mq(l) {
    padding: 0.9rem 2.5rem;
  }
}

[class*='action--'][class*='--reverse'] {
  flex-direction: row-reverse;
}

[class*='action--'][class*='--white'] {
  color: $c-white;
  background-color: transparent;
  outline-color: $c-white;

  &:hover {
    color: $c-main;
    background: $c-white;
  }
}

[class*='action--'][class*='--shadow'] {
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    box-shadow: 0 0.5rem 2rem rgba($c-black, 20%);
  }
}

[class*='action--'][class*='--nomargin'] {
  margin: 0;
}

[class*='action--'][class*='--close'] {
  text-indent: -99999rem;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.2rem;
    height: 50%;
    background: $c-white;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

[class*='action--'][class*='--icon'] {
  display: inline-flex;
  align-items: center;
  gap: $spacing * 0.5;
}

.action__icon {
  display: flex;
  fill: currentcolor;
  width: 2.4rem;
  height: 2.4rem;
}
</style>
