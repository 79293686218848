import { defineStore } from 'pinia'

interface UiState {
  noScroll: boolean
  hasTransition: 'page' | 'menu' | 'none'
  hasOnboarding: boolean
  waitForHero: boolean
  showHeader: boolean
  showModal: boolean
}

const defaultState = (): UiState => ({
  noScroll: false,
  hasTransition: 'none',
  hasOnboarding: false,
  waitForHero: false,
  showHeader: true,
  showModal: false,
})

export const useUiStore = defineStore('ui', {
  state: defaultState,
  actions: {
    toggleScroll(value?: boolean) {
      this.noScroll = value ?? !this.noScroll
      if (value === false) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
    },
    toggleHeader(value?: boolean) {
      this.showHeader = value || !this.showHeader
    },
    toggleModal(value?: boolean) {
      this.showModal = value === undefined ? !this.showModal : value
    },
  },
})

export const hasScroll = () => window.innerWidth > document.body.clientWidth
