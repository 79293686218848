<template>
  <div
    ref="rootEl"
    class="focus p-xl"
    :class="`bg-${content.background_color || 'cobalt'}`"
  >
    <div ref="contentEl" class="focus__content wrapper">
      <div class="focus__headline" v-html="content.headline"></div>

      <h1 class="focus__title" v-html="content.title"></h1>

      <div
        v-if="content.text"
        class="focus__text mt-l"
        v-html="content.text"
      ></div>

      <div v-if="content.links?.length" class="focus__links mt-l">
        <Action
          v-for="(link, index) in content.links.filter(
            link => link !== undefined
          )"
          :key="link?.url || index"
          :content="{
            ...link,
            modifiers:
              index === 0 ? ['btn', 'cta'] : ['btn', 'bordered', 'white'],
          }"
          class="focus__links__item"
          @click="onActionClick($event, link as Link)"
        />
      </div>

      <div v-if="content.link" class="focus__links mt-l">
        <Action
          :content="{
            ...content.link,
            modifiers: ['btn'],
          }"
          class="focus__links__item"
        />
      </div>
    </div>

    <figure v-if="content.picture" ref="bgEl" class="focus__picture">
      <img
        ref="imgEl"
        v-src="content.picture"
        :alt="content.picture.alt"
        sizes="(min-width: 960px) 60vw, 100vw"
      />
    </figure>
  </div>
</template>

<script lang="ts">
import type { Link, Picture } from '@/types'

export interface FocusBasic {
  background_color: string
  headline: string
  hide_logo: boolean
  link?: Link
  links?: Array<Link | undefined>
  logo?: string
  picture?: Picture
  text: string
  title: string
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted, ref } from 'vue'

import { useUiStore } from '@/stores/ui'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

gsap.registerPlugin(ScrollTrigger)

defineProps({
  content: {
    type: Object as PropType<FocusBasic>,
    required: true,
  },
})

// Refs
const rootEl = ref<HTMLElement | null>(null)
const bgEl = ref<HTMLElement | null>(null)
const imgEl = ref<HTMLElement | null>(null)
const contentEl = ref<HTMLElement | null>(null)

const ui = useUiStore()

const emit = defineEmits<{
  'modal-trigger': []
}>()

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
      ui.waitForHero = false

      // Add scrolling animation on picture
      if (imgEl.value) {
        gsap.to(imgEl.value, {
          y: 100,
          ease: 'power4.easeOut',
          scrollTrigger: {
            trigger: rootEl.value,
            start: 'top top',
            end: 'bottom top',
            scrub: 0,
            id: 'focus-ribbon',
          },
        })
      }
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' }).fromTo(
    rootEl.value,
    {
      clipPath: 'inset(0% 0% 20% 0%)',
    },
    {
      clipPath: 'inset(0% 0% 0% 0%)',
      ease: '8020',
      duration: 0.5,
    }
  )

  if (bgEl.value) {
    animatedEls.push(bgEl.value)

    tl.fromTo(
      bgEl.value,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.8,
        ease: '8020',
      },
      0
    )
  }

  if (contentEl.value) {
    const contentChildrenEl = contentEl.value.querySelectorAll(':scope > *')

    animatedEls.push(...contentChildrenEl)

    tl.fromTo(
      contentChildrenEl,
      {
        x: -30,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.05,
        ease: '8020',
      },
      '<+=0.1'
    )
  }

  if (imgEl.value) {
    animatedEls.push(imgEl.value)

    tl.fromTo(
      imgEl.value,
      {
        opacity: 0,
        scale: 1.2,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.8,
        ease: '8020',
      },
      0
    )
  }
}

// Intercept button that should open modal
const onActionClick = (e: PointerEvent, link?: Link) => {
  if (!link?.url?.includes('#order')) {
    return
  }

  e.preventDefault()
  emit('modal-trigger')
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  // Init hero appear
  ui.waitForHero = true
  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(
    rootEl.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})
</script>

<style lang="scss" scoped>
$bp: m;

.focus {
  position: relative;
  display: flex;
  overflow: hidden;
  background: var(--cg-accent);

  @include mq($until: $bp) {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 0;
  }

  @include mq($bp) {
    align-items: center;
    overflow: hidden;
  }

  @include mq(xl) {
    min-height: 60vh;
  }
}

.focus__content {
  position: relative;
  z-index: 1;
  color: $c-white;
}

.focus__headline {
  margin-bottom: $spacing;

  @include mq($bp) {
    margin-bottom: $spacing * 0.5;
  }
}

.focus__title {
  @include mq($bp) {
    padding-right: col(5, var(--available-columns));
  }
}

.focus__text {
  @include mq($bp) {
    padding-right: col(6, var(--available-columns));
  }

  @include mq(wrapper) {
    padding-right: col(5, var(--available-columns));
  }
}

.focus__links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: $spacing * 0.5;
}

.focus__picture {
  padding-left: col(2);

  img {
    width: 100%;
  }

  @include mq($bp) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    height: 100%;
    padding: 0;

    img {
      @include image-fit(contain, bottom right);
    }
  }
}
</style>
