import { createI18n } from 'vue-i18n'

import { getLanguageAvailable, getLanguageDefault } from '@/config/languages'
import translationKeys from '@/i18n/locales/translationKeys.json'

import type { Chrome } from '@/stores/chrome'
import type { Country, Language } from '@/types'

export const initI18n = (c: Country) => {
  const languageAvailable = getLanguageAvailable(c)
  const languageDefault = getLanguageDefault(c)
  const messages = languageAvailable.reduce(
    (acc, lang) => {
      acc[lang] = {}

      return acc
    },
    {} as Record<Language, Record<string, string>>
  )

  messages[languageDefault] = translationKeys // locales are fetched with the chrome

  const instance = createI18n({
    legacy: false,
    locale: languageDefault,
    fallbackLocale: languageDefault,
    globalInjection: true,
    messages,
  })

  function setLocale(
    lang: Language,
    locales: Chrome['i18n'],
    isClient: boolean
  ) {
    // Set locale
    instance.global.locale.value = lang

    if (isClient) {
      // Not really needed because of `vue-meta`…
      // however document is only available client side
      document.documentElement.lang = lang
    }

    // Update messages with locales
    instance.global.setLocaleMessage(lang, locales)

    return lang
  }

  return { instance, setLocale }
}
