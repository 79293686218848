/* eslint-disable camelcase */
import { useAxios } from '@monofront/vue-axios-plugin'
import { defineStore } from 'pinia'
import { shallowRef } from 'vue'

import { getApiUrl } from '@/config/app'
import { getLanguageDefault } from '@/config/languages'
import { useCountry } from '@/plugins/country'
import { logger } from '@/utils/logger'

import type { AxiosError, AxiosResponse } from '@monofront/vue-axios-plugin'
import type { HeadAttrs } from '@vueuse/head'
import type { Language, Picture } from '@/types'

// Edenred
interface Link {
  label: string
  url: string
  target?: string
}

interface NavItem {
  label: string
  text?: string
  target?: string
  url: string
  picture?: object
  children?: Link[] &
    {
      classes: string
    }[]
}

interface Announce {
  color: 'red' | 'blue'
  text: string
  link?: Link
}

export interface ChromeState {
  announce?: Announce
  cookies: string
  coordinates: Record<string, string>
  footer_menu: NavItem[]
  i18n: Record<string, string>
  language: Language
  legal_menu: NavItem[]
  main: {
    label: string
    text?: string
    url: string
    isCta?: boolean
    classes?: string
    caption?: string
    children?: {
      label: string
      url: string
      classes?: string
    }[]
    last_news?: {
      title: string
      url: string
      picture: Picture
    }
    sector: string
  }[]
  main_menu: []
  myedenred: Record<string, string>
  script: HeadAttrs[]
  secondary_menu: {
    url: string
    label: string
  }[]
  social_networks: Record<string, string>
}

export interface ChromeResponse extends ChromeState {}

export type Chrome = ChromeState

export const useChromeStore = defineStore('chrome', () => {
  const axios = useAxios()
  const country = useCountry()
  const languageDefault = getLanguageDefault(country)

  const announce = shallowRef<ChromeState['announce']>({} as Announce)
  const cookies = shallowRef<ChromeState['cookies']>('')
  const coordinates = shallowRef<ChromeState['coordinates']>({})
  const footer_menu = shallowRef<ChromeState['footer_menu']>([])
  const i18n = shallowRef<ChromeState['i18n']>({})
  const language = shallowRef(languageDefault)
  const main_menu = shallowRef<ChromeState['main_menu']>([])
  const main = shallowRef<ChromeState['main']>([])
  const myedenred = shallowRef<ChromeState['myedenred']>({})
  const script = shallowRef<HeadAttrs[]>([])
  const secondary_menu = shallowRef<ChromeState['secondary_menu']>([])
  const social_networks = shallowRef<ChromeState['social_networks']>({})
  const legal_menu = shallowRef<ChromeState['legal_menu']>([])

  async function fetchChrome(l: Language = languageDefault) {
    logger.info('[fetchChrome]', l, `${getApiUrl(country)}/chrome`)
    try {
      const response = (await axios.get(
        `${getApiUrl(country)}/chrome`
      )) as AxiosResponse<ChromeResponse>
      const data = response?.data

      language.value = l

      if (data.announce) {
        announce.value = data.announce
      }
      cookies.value = data.cookies
      coordinates.value = data.coordinates
      footer_menu.value = data.footer_menu
      i18n.value = data.i18n
      language.value = data.language
      main_menu.value = data.main_menu
      main.value = data.main
      myedenred.value = data.myedenred
      script.value = data.script
      secondary_menu.value = data.secondary_menu
      social_networks.value = data.social_networks
      legal_menu.value = data.legal_menu
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        logger.error(
          '[fetchChrome]',
          (error as AxiosError).code,
          (error as AxiosError).message
        )
      } else {
        logger.error('[fetchChrome]', (error as Error).message)
      }
    }
  }

  return {
    announce,
    cookies,
    coordinates,
    footer_menu,
    i18n,
    language,
    main_menu,
    main,
    myedenred,
    script,
    secondary_menu,
    social_networks,
    legal_menu,
    fetchChrome,
  }
})
