<template>
  <div v-if="options.length > 0" :class="customStyles.dropdown">
    <div
      v-if="label"
      :id="id"
      class="label"
      :class="[customStyles.label]"
      :aria-hidden="!showLabel"
    >
      {{ label }}
    </div>
    <div class="select" :class="customStyles.select">
      <button
        ref="triggerEl"
        class="trigger"
        :class="[
          customStyles.trigger,
          { [customStyles.defaultTrigger]: !hasCustomTrigger },
        ]"
        :aria-controls="`${id}-listbox`"
        :aria-expanded="isExpanded"
        aria-haspopup="listbox"
        :aria-labelledby="id"
        @click="onClick"
      >
        <slot name="trigger" :label="triggerLabel" :is-expanded="isExpanded">
          <span>{{ triggerLabel }}</span>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0,0l12,0l-6,8Z" />
          </svg>
        </slot>
      </button>
      <ul
        v-show="isExpanded"
        :id="`${id}-listbox`"
        class="list"
        :class="customStyles.list"
        role="listbox"
        tabindex="-1"
        :aria-labelledby="id"
        :aria-activedescendant="active.value"
      >
        <li
          v-for="(option, i) in options"
          :id="option.value"
          :key="`${id}-${i}-option`"
          class="option"
          tabindex="-1"
          :class="[
            customStyles.option,
            {
              [customStyles.defaultOption]: !hasCustomOption,
              [customStyles.isActive]: option.value === active.value,
              [customStyles.isSelected]: option.value === selected.value,
              'is-active': option.value === active.value,
              'is-selected': option.value === selected.value,
            },
          ]"
          role="option"
          :aria-selected="option.value === active.value"
          @mousedown="onSelect(i)"
        >
          <slot name="option" :label="option.label">
            {{ option.label }}
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line lines-around-comment
/**
 * Resources:
 *
 * - https://pattern-library.dequelabs.com/components/selects
 * - https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html
 * - https://www.webaxe.org/accessible-custom-select-dropdowns/
 */

/**
 * Slots:
 *
 * trigger: isExpanded, label
 * option: label
 */

/**
 * CSS:
 *
 * .dropdown
 * .label
 * .select
 * .trigger: [aria-expanded]
 * .list
 * .option: .isActive, .isSelected
 */
import { computed, onMounted, ref, useSlots, watch } from 'vue'

import styles from './dropdown.module.scss'

import type { PropType } from 'vue'

interface Option {
  label: string
  value: string
}

type CustomStyle = Record<string, string>

const props = defineProps({
  id: {
    type: String, // REVIEW: TS ?
    required: true,
  },
  options: {
    type: Array as PropType<Option[]>, // REVIEW: TS ?
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  // REVIEW: may be some visually-hidden ?
  showLabel: {
    type: Boolean,
    default: true,
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  classes: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  css: {
    type: Object as PropType<CustomStyle>,
    default: () => ({}) as Record<string, never>,
  },
})
const emit = defineEmits(['open', 'close', 'input'])
const slots = useSlots()

// Checks
const hasCustomTrigger = slots.trigger !== undefined
const hasCustomOption = slots.option !== undefined
let hasInit = false
let canToggle = false

const triggerEl = ref<HTMLElement>()
let blurTimeout: ReturnType<typeof setTimeout>
let searchTimeout: ReturnType<typeof setTimeout>

// Statuses
const isExpanded = ref(false)
const hasSelection = ref(props.value !== '')

// Values
let initialIndex = -1

if (hasSelection.value) {
  const index = props.options.findIndex(o => props.value === o.value)

  if (index !== -1) {
    initialIndex = index
  }
}
const selectedIndex = ref(initialIndex)
const activeIndex = ref<number>(selectedIndex.value)
const selected = computed<Option | { value: undefined }>(() =>
  hasSelection.value ? props.options[selectedIndex.value] : { value: undefined }
)
const active = computed<Option | { value: undefined }>(() =>
  hasSelection.value ? props.options[activeIndex.value] : { value: undefined }
)
const triggerLabel = computed(() =>
  selected.value.value === undefined
    ? props.placeholder
    : (selected.value as Option).label
)

// Manage external change (via `props.value`)
watch(
  () => props.value,
  (val, prev) => {
    if (val !== prev) {
      selectedIndex.value = props.options.findIndex(o => val === o.value)
      activeIndex.value = selectedIndex.value
      hasSelection.value = true
    }
  }
)

// Search needle
let needle = ''

// Custom styles
const customStyles = computed(() =>
  Object.keys(styles).reduce((acc: CustomStyle, prop: string) => {
    const base: string = styles[prop]
    const extra = Object.keys(props.css).find(name => name === prop)

    acc[prop] = extra ? `${props.css[extra]} ${base}` : base

    return acc
  }, {})
)

const open = () => {
  activeIndex.value = selectedIndex.value
  isExpanded.value = true
  emit('open')
}

const close = () => {
  isExpanded.value = false
  emit('close')
}

const toggle = () => {
  if (isExpanded.value) {
    // Do not close on focus triggered by first button click
    canToggle && close()
  } else {
    open()
  }
}

const init = () => {
  if (props.options.length === 0) {
    return
  }

  !hasInit && document.addEventListener('keydown', onKeydown)
  hasInit = true
  open()
  canToggle = false
  setTimeout(() => {
    canToggle = true
  }, 250)
}

const destroy = () => {
  document.removeEventListener('keydown', onKeydown)
  hasInit = false
  close()
  triggerEl.value!.blur()
}

const select = () => {
  selectedIndex.value = activeIndex.value
  hasSelection.value = true
  triggerEl.value!.focus()
  emit('input', selected.value.value)
  selected.value && emit('input', selected.value.value)
}

const search = (input: string) => {
  const cleanString = (str: string) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
  // Normalize input
  needle += cleanString(input)

  const result = props.options.findIndex(o =>
    cleanString(o.label).startsWith(needle)
  )

  if (result > -1) {
    activeIndex.value = result
  }

  clearTimeout(searchTimeout)
  searchTimeout = setTimeout(() => {
    needle = ''
  }, 300)
}

const onKeydown = (event: KeyboardEvent) => {
  if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
    return
  }

  // Prevent page scroll
  event.preventDefault()

  switch (event.key) {
    // Move selection
    case 'ArrowUp':
      !isExpanded.value && open()
      activeIndex.value = Math.max(activeIndex.value - 1, 0)
      break
    case 'ArrowDown':
      !isExpanded.value && open()
      activeIndex.value = Math.min(
        activeIndex.value + 1,
        props.options.length - 1
      )
      break
    case 'Home':
      !isExpanded.value && open()
      activeIndex.value = 0
      break
    case 'End':
      !isExpanded.value && open()
      activeIndex.value = props.options.length - 1
      break
    // Close and leave
    case 'Tab':
      destroy()
      break
    // Close and stay
    case 'Escape':
      close()
      break
    // Select and toggle
    case 'Enter':
    case ' ':
      isExpanded.value && select()
      toggle()
      break
    // Search
    default:
      // Quick filter (to avoid f5, Ctrl, …)…
      if (event.key.length === 1) {
        !isExpanded.value && open()
        search(event.key)
      }
  }
}

const onSelect = (index: number) => {
  // Prevent blur callback
  clearTimeout(blurTimeout)
  activeIndex.value = index
  select()
  close()
}

const onClick = () => {
  toggle()
}

onMounted(() => {
  if (!triggerEl.value || props.options.length === 0) {
    return
  }

  triggerEl.value.addEventListener('focus', init)
  triggerEl.value.addEventListener('blur', () => {
    // This timeout allows blur+click combo aka option click
    blurTimeout = setTimeout(() => {
      isExpanded.value && destroy()
    }, 150)
  })
})
</script>
