<template>
  <div class="player">
    <div class="player__content" data-staggered>
      <button
        v-if="!isPlaying"
        data-staggered
        class="button"
        @click="isPlaying = true"
      >
        <div class="player__icon">
          <SvgSprite
            class="icon"
            symbol="ui-caret-right"
            size="20"
            aria-hidden="true"
          />

          <span>play</span>
        </div>
        <img
          v-if="!isPlaying"
          :src="`https://img.youtube.com/vi/${content.url}/maxresdefault.jpg`"
          class="player__poster"
        />
      </button>

      <div v-if="isPlaying" class="player__player">
        <iframe
          v-if="isPlaying"
          class="player__iframe"
          :src="`https://www.youtube.com/embed/${content.url}?autoplay=1&enablejsapi=1`"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { PropType } from 'vue'

const isPlaying = ref(false)

export interface VideoT {
  url?: string
}

// Props
defineProps({
  content: {
    type: Object as PropType<VideoT>,
    required: true,
  },
})
</script>

<style lang="scss" scoped>
.player__player {
  position: relative;
}

.player__iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.player__poster {
  @include get-all-space;

  object-fit: cover;
  object-position: center;
  z-index: 1;
  transition: transform 0.2s ease-in-out;
}

.button {
  @include get-all-space;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border: none;
  transition: transform 0.2s ease-in-out;
  transform-origin: center;
  cursor: pointer;

  span {
    font-family: $ff-alt;
    font-weight: 300;
  }

  svg {
    fill: $c-red;
    transform: translateX(0.2rem);
  }
}

.player__content {
  position: relative;
  overflow: hidden;
  margin: auto;
  aspect-ratio: 16 / 9;

  &:hover {
    img,
    .button {
      transform-origin: center;
      transform: scale(1.03);
    }
  }
}

.player__icon {
  z-index: $header-z-index;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 3rem;
  background-color: $c-white;
  border-radius: 6rem;

  span {
    color: $c-red;
    font-size: 2.4rem;
  }
}
</style>
