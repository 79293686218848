// Routes + endpoints examples…
// 1. segment is not translated and is the same as the endpoint name
// {
//   path: '/same-name/',
//   meta: { endpoint: 'same-name' },
// },
//
// 2. segment is translated
// {
//   path: `/${formatSegment('name')}/`,
//   meta: { endpoint: 'name' },
// },
//
// 3. segment not the same as the endpoint name
// @example https://site.com/segment-name/ => https://cms.com/api/v1/endpoint-name
// {
//   path: '/:pattern(segment-name)/',
//   meta: { endpoint: 'endpoint-name' },
// },

import { getLanguageAvailable, getLanguageDefault } from '@/config/languages'
import Root from '@/views/Root.vue'

import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import type { Country } from '@/types'

// Route segments
export const segments = {
  // foo: ['foo-fr', 'foo-nl'],
  // bar: 'bar-bar',
  demos: ['demos', 'demonstrations'],
  news: ['actualite', 'nieuws', 'news'],
  release: ['presse', 'pers'],
  whitepaper: ['white-papers'],
} as Record<string, string | string[]>

// Format translated route segments
// formatSegment('foo') => ':segment(foo-fr|foo-nl)'
// formatSegment('bar', 'qux) => ':qux(bar-bar)'
const formatSegment = (segmentKey: string, paramName = 'segment'): string =>
  Array.isArray(segments[segmentKey])
    ? `:${paramName}(${(segments[segmentKey] as string[]).join('|')})`
    : `:${paramName}(${segments[segmentKey] as string})`

const routes: RouteRecordRaw[] = []
const createRoutes = (c: Country) => {
  const languageAvailable = getLanguageAvailable(c)

  let routes: RouteRecordRaw[] = [
    {
      path: '/',
      component: () => import('@/views/Homepage.vue'),
      name: 'homepage',
      // meta: {
      //   static: true,                  // No automatic call to API (WP)
      //   endpoint: 'posts',             // Specific endpoint
      //   api: 'http://some-other-api',  // Use different API address
      //   key: 'id',                     // Avoid component re-rendering
      //   root: true,                    // Root route even with languages
      // },
    },
    {
      path: '/dev',
      component: () => import('@/views/Dev.vue'),
      name: 'dev',
      meta: {
        static: true,
        chrome: false,
      },
    },
    {
      path: '/styleguide',
      component: () => import('@/views/Styleguide.vue'),
      name: 'styleguide',
      meta: {
        static: true,
      },
    },
    {
      path: `/${formatSegment('whitepaper')}/`,
      component: () => import('@/views/pages/WhitePaper.vue'),
      name: 'white-paper-archive',
    },
    {
      path: `/${formatSegment('whitepaper')}/:slug`,
      component: () => import('@/views/pages/single/WhitePapers.vue'),
      name: 'white-papers-single',
      meta: {
        scrollTop: true,
        endpoint: 'white-papers',
      },
    },
    {
      path: `/${formatSegment('demos')}/`,
      component: () => import('@/views/Page.vue'),
      name: 'page',
    },
    // News
    {
      path: `/${formatSegment('news', 'pattern')}/:slug/`,
      meta: { endpoint: 'news' },
      component: () => import('@/views/Single.vue'),
      name: 'news',
    },
    // Releases
    {
      path: `/${formatSegment('release', 'pattern')}/:slug/`,
      meta: { endpoint: 'release' },
      component: () => import('@/views/Single.vue'),
      name: 'release',
    },
    {
      path: '/:page/:subpage(.*)?',
      component: () => import('@/views/Page.vue'),
      name: 'page',
    },
    {
      path: '/:catchAll(.*)',
      name: 'catch-all',
      component: () => import('@/views/404.vue'),
    },
  ]

  // (~~If~~) multilanguage, add lang segment
  const initialRoutes = [...routes]

  routes = [
    {
      path: `/:lang(${languageAvailable.join('|')})`,
      component: Root as Component,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        // Remove catch-all route (there should be only one)
        // and "root" routes (that will stay at the root level)
        .filter(r => r.name !== 'catch-all' && r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${getLanguageDefault(c)}`, // `/${navigator.language.split('-')[0] || languageDefault}`
    },
    {
      path: '/:catchAll(.*)',
      name: 'catch-all',
      component: () => import('@/views/404.vue'),
    },
  ]

  // Keep "root" routes
  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))

  return routes
}

export { routes, createRoutes }
