/* stylelint-disable selector-max-combinators */
<template>
  <!-- footer -->
  <footer class="footer">
    <div class="footer__inner">
      <!-- footer / Logo -->
      <div class="footer-logo">
        <RouterLink :to="`/${locale}/`" class="header__logo">
          <SvgSprite
            symbol="ui-logo"
            size="132"
            role="img"
            aria-label="disable"
          />
        </RouterLink>

        <!-- footer / Social media -->
        <div class="footer-social">
          <p class="footer-social__follow">{{ t('social.suivez-nous') }}</p>
          <ul class="footer-social-list">
            <template v-for="(link, j) in content.social_networks" :key="j">
              <li v-if="link" class="footer-social-item">
                <a :href="link" target="_blank" class="footer-social-link">
                  <SvgSprite
                    :symbol="`social-${j}`"
                    size="22"
                    role="img"
                    aria-label="disable"
                  />
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div class="footer-bloc">
        <!-- footer / nav -->
        <div class="footer-nav">
          <div
            v-for="(item, index) in content.footer_menu"
            :key="index"
            class="footer-nav-column"
          >
            <p class="footer-nav-title">{{ item.label }}</p>
            <ul class="footer-nav-list">
              <li
                v-for="(child, i) in item.children"
                :key="i"
                :class="child.classes"
              >
                <router-link
                  v-if="!child.target"
                  class="footer-nav-link link"
                  :to="child.url"
                >
                  {{ child.label }}
                </router-link>

                <a
                  v-else
                  class="footer-nav-link link"
                  :href="child.url"
                  :target="child.target"
                >
                  {{ child.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-links">
          <div class="footer-space">
            <p>
              <span>{{ t('my.consultez-votre') }}</span>
              <strong>{{ t('my.espace-personnel') }}</strong>
            </p>

            <Action
              v-if="content.myedenred.website_url"
              class="mt-s"
              :content="{
                title: 'MyEdenred',
                url: content.myedenred.website_url,
                modifiers: ['btn'],
              }"
            />
          </div>
        </div>

        <!-- footer / Copyright -->
        <div class="footer-copyright">
          Copyright © 2024 Edenred Belgium
          <ul class="footer-copyright__wrapper">
            <li
              v-for="(item, index) in content.legal_menu"
              :key="index"
              class="footer-copyright__wrapper__link-outer"
            >
              <a
                v-if="item.target"
                class="footer-copyright__wrapper__link link"
                :href="item.url"
                target="_blank"
              >
                {{ item.label }}
              </a>

              <router-link
                v-else
                class="footer-copyright__wrapper__link link"
                :to="item.url"
              >
                {{ item.label }}
              </router-link>
            </li>
            <li class="footer-copyright__wrapper__link-outer">
              <button
                class="footer-copyright__wrapper__link link"
                @click="onClickCookieSettings()"
              >
                <span>{{ t('global.cookie-settings') }}</span>
              </button>
            </li>
          </ul>
        </div>

        <!-- footer / Go Top -->
        <div class="footer-go-top" @click="onGoTopClick()">
          <div class="footer-go-topIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="28px"
              height="20px"
            >
              <path
                fill-rule="evenodd"
                stroke="rgb(22, 32, 86)"
                stroke-width="4px"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                fill="none"
                d="M2.853,13.147 L13.306,2.547 L23.759,13.147 "
              />
            </svg>
          </div>
          <span>Top</span>
        </div>
      </div>
    </div>
  </footer>
  <!-- /footer -->
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useChromeStore } from '@/stores/chrome'

const content = useChromeStore()
const { locale, t } = useI18n()

const onGoTopClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const onClickCookieSettings = () => {
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay()
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  padding: 4rem 5%;
  color: $c-white;
  background-color: $c-black;

  // :host-context(.Wrapper--set1) & {
  // background: red;
  // }

  // :host-context(.Wrapper--set2) & {
  // background: red;
  // }

  // :host-context(.Wrapper--set3) & {
  // background: red;
  // }

  :deep(.footer-copyright__wrapper__link) {
    position: relative;

    // font-family: $ff-edenred;
    color: $c-white;
    font-size: 15px;
    font-weight: 200;
    line-height: 1;
    cursor: pointer;

    // Onetrust
    /* stylelint-disable-next-line selector-max-id */
    &:is(button),
    &#ot-sdk-btn.ot-sdk-show-settings {
      padding: 0;
      color: $c-white;
      line-height: 1.2;
      background: none;
      border: 0;

      /* stylelint-disable-next-line selector-max-id */
      &:hover,
      &:focus {
        background: none;
      }
      /* stylelint-disable-next-line selector-max-id */
      span::after {
        content: none;
      }
    }

    span {
      position: relative;

      /* stylelint-disable-next-line no-descending-specificity */
      &::after {
        content: '';
        position: absolute;
        bottom: -0.1rem;
        left: 0;
        width: 100%;
        height: 0.1rem;
        background: $c-white;
        transform: scaleX(0);
        transform-origin: center left;
        transition: transform 0.3s ease-out;
      }
    }

    @include mq(xs) {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(-1.5rem / 2);
        width: 0.1rem;
        height: 90%;
        background: $c-white;
        transform: translateY(-50%);
      }

      li:first-child & {
        margin-left: 0;
        padding-left: 0;

        /* stylelint-disable-next-line max-nesting-depth */
        &::before {
          display: none;
        }
      }
    }

    @include mq(m) {
      li:first-child & {
        /* stylelint-disable-next-line max-nesting-depth */
        &::before {
          display: block;
        }
      }
    }
  }

  @include mq(xl) {
    @include fit-grid-padding;

    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.footer__inner {
  width: 100%;

  @include mq(l) {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: flex-start;
  }
}

.footer-logo {
  svg {
    fill: $c-white;
  }

  @include mq(l) {
    margin-top: 0;
  }
}

.footer-logo-svg path {
  fill: $c-white;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  margin-bottom: 16rem;
}

.footer-nav-column {
  width: calc(100% / 3);

  .padded {
    margin-top: 3rem;
  }
}

.footer-nav-title {
  flex: 0;
  margin-top: 0;
  margin-bottom: 2rem;
  font-family: $ff-alt;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.footer-nav-list {
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    line-height: 3.2rem;

    a {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

:host :deep() {
  .footer-nav-link {
    position: relative;
    color: $c-white;
    font-size: $s-font-size-m;
    font-weight: 400;
    line-height: 2.7rem;

    &--big {
      display: block;
      color: $c-white;
      font-size: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      width: 100%;
      height: 0.1rem;
      background: $c-white;
      transform: scaleX(0);
      transform-origin: center left;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    @include mq(xl) {
      font-size: $s-font-size-xl;
      line-height: 3.8rem;
    }
  }
}

.footer-links {
  @include mq(l) {
    display: flex;
    justify-content: space-between;
  }
}

.footer-space {
  flex-basis: 25%;

  p {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  strong {
    display: block;
  }
}

.footer-social {
  flex-basis: 33.1%;
  margin-top: 2rem;
  margin-bottom: 40px;
  text-align: center;
}

.footer-social svg {
  fill: $c-black;
}

.footer-social__follow {
  font-size: 1.6rem;
  text-align: left;
}

.footer-social-list {
  list-style: none;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
  text-align: left;
}

.footer-social-item {
  display: inline-block;
  vertical-align: top;
  margin: 0 9px;

  &:first-child {
    margin-left: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 3.6rem;
    height: 3.6rem;
    background-color: $c-white;
    border-radius: 50%;
    transition: background-color 0.2s ease-out;

    /* stylelint-disable-next-line no-descending-specificity */
    &:hover {
      background: $c-white;
    }
  }
}

.footer-copyright {
  flex-basis: 100%;
  margin: 0;
  margin-top: 5rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;

  @include mq(m) {
    display: flex;
    align-items: baseline;
  }
}

.footer-copyright__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-left: 1.5rem;
  padding-left: 0;
  list-style-type: none;
  gap: 1.5rem;

  @include mq(m) {
    margin-top: 0;
  }
}

.footer-go-top {
  display: none;
}

@include mq($until: l) {
  .footer {
    position: relative;
    display: block;
    padding: 5rem 5% 2rem;
  }

  .footer-nav {
    display: none;
  }

  .footer-nav-column {
    display: block;
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .footer-nav-title {
    margin: 15px 0;
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
  }

  .footer-nav-list {
    display: none;
  }

  .footer-logo {
    position: relative;
    top: 0;
    left: 0;
    float: none;
  }

  .footer-logo svg {
    width: 91px;
  }

  .footer-space {
    margin: 4rem 0;

    // font-family: $ff-edenred;
    font-size: 1.8rem;
    line-height: 2.8rem;

    strong {
      display: block;
    }

    a {
      margin-top: 1.5rem;
    }
  }

  .footer-social {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  .footer-social__follow {
    text-align: left;
  }

  .footer-social-list {
    text-align: left;
  }

  .footer-social-item {
    max-width: 15%;
    margin: 0 10px;
  }

  .footer-social-link {
    overflow: hidden;

    svg {
      @include mq(m) {
        width: 35px;
        height: 35px;
      }
    }
  }

  .footer-copyright {
    margin-top: 4rem;
  }

  .footer-go-top {
    display: none;
  }
}

@include mq(l, xxl) {
  .footer-logo {
    position: relative;
    top: 0;
    left: 0;
  }

  .footer-nav-title {
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
