import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { SplitText } from 'gsap/SplitText'

import { useUiStore } from '@/stores/ui'
import { useAppear } from '@/utils/aware'

import type { MaybeElementRef } from '@vueuse/core'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(CustomEase)

export const ease = CustomEase.create('8020', '0.37, 0, 0, 1')

/**
 * Returns a promise that will resolve when onboarding has ended.
 * If no transition is running, will resolve immediatly.
 * @returns Promise<void>
 */
export const awaitOnboarding = () => {
  const ui = useUiStore()

  return new Promise<void>(resolve => {
    if (ui.hasOnboarding) {
      const unsubscribe = ui.$subscribe(() => {
        if (!ui.hasOnboarding) {
          unsubscribe()
          resolve()
        }
      })
    } else {
      resolve()
    }
  })
}

/**
 * Returns a promise that will resolve when current transition has ended.
 * If no transition is running, will resolve immediatly.
 * @returns Promise<void>
 */
export const awaitPageTransition = () => {
  const ui = useUiStore()

  return new Promise<void>(resolve => {
    if (ui.hasTransition === 'none') {
      resolve()
    } else {
      const unsubscribe = ui.$subscribe(() => {
        if (ui.hasTransition === 'none') {
          unsubscribe()
          resolve()
        }
      })
    }
  })
}

/**
 * Returns a promise that will resolve when the hero has been revealed
 * If there is no hero, will resolve immediatly.
 * Prevents page component from appearing before hero
 * @returns Promise<void>
 */
export const awaitHeroAppear = () => {
  const ui = useUiStore()

  return new Promise<void>(resolve => {
    if (!ui.waitForHero || window.scrollY > 0) {
      resolve()
    } else {
      const unsubscribe = ui.$subscribe(() => {
        if (!ui.waitForHero) {
          unsubscribe()
          resolve()
        }
      })
    }
  })
}

/**
 * Runs callback function when target element enter viewport + no page transition is running
 * @param target
 * @param callback
 */
export function onAppear(
  target: MaybeElementRef,
  callback?: () => void,
  options: Record<string, string | boolean> = {
    once: true,
    rootMargin: '0px 0px -10% 0px',
    waitForHero: true,
  }
) {
  useAppear(
    target,
    async ({ isInViewport }) => {
      if (isInViewport) {
        await awaitOnboarding()
        await awaitPageTransition()

        if (options.waitForHero) {
          await awaitHeroAppear()
        }

        if (callback) {
          callback()
        } else if (target instanceof Element) {
          // Add basic fade in when no callback is specified
          gsap.fromTo(
            target,
            { opacity: 0 },
            {
              opacity: 1,
              onComplete: () => {
                gsap.set(target, { clearProps: 'opacity' })
              },
            }
          )
        }
      }
    },
    options
  )
}

export const prefersReducedMotion = () =>
  window.matchMedia('(prefers-reduced-motion: reduce)').matches
