import viteSSR, { ClientOnly } from '@monofront/vite-ssr/vue'
import { createAxios } from '@monofront/vue-axios-plugin'
import devalue from '@nuxt/devalue'
// Vue plugins
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { readonly } from 'vue'
import { svgSpritePlugin } from 'vue-svg-sprite'

import Action from '@/components/global/Action.vue'
import HtmlText from '@/components/global/HtmlText.vue'
import Lazy from '@/components/global/Lazy.vue'
import GPlayer from '@/components/global/Player.vue'
import { getCountryFromHostname } from '@/config/countries'
import { getLanguageDefault } from '@/config/languages'
// Vue components
import App from '@/core/App.vue'
import { ContentKey, contentRef } from '@/core/resource'
// Vue directives
import outside from '@/directives/outside'
import src from '@/directives/src'
// Vue plugins
import { initI18n } from '@/i18n'
import { createCountry } from '@/plugins/country'
import { createGuards } from '@/router/guards'
import { createRoutes, routes } from '@/router/routes'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'

// import { push } from '@/utils/tracking'

import '@/styles/main.scss'
import 'virtual:svg-icons-register'

import { loadFonts } from '@/config/fonts'
import { logger } from '@/utils/logger'

import type { Language } from '@/types'

export default viteSSR(
  App,
  {
    routes,
    // https://pinia.esm.dev/ssr/#state-hydration
    transformState(state) {
      return import.meta.env.SSR ? devalue(state) : state
    },
    pageProps: {
      passToPage: false, // ALL props will be passed to the page component.
      // [Vue warn]: Extraneous non-props attributes (head) were passed to component
      // but could not be automatically inherited because component renders fragment or text root nodes.
    },
  },
  {
    pre: context => {
      const { router, url } = context
      // Edenred country detection
      // Try command process first, then check top level domain
      const country = getCountryFromHostname(url.hostname)
      const routes = createRoutes(country)

      routes.forEach(router.addRoute)
    },
    main: async context => {
      const { app, initialState, initialRoute, url } = context
      const country = getCountryFromHostname(url.hostname)

      import.meta.env.VITE_RELEASE !== 'production' && console.time('font')
      if (!import.meta.env.SSR) {
        await loadFonts()
        logger.log(document.fonts.size, 'FontFaces loaded.')
      }
      import.meta.env.VITE_RELEASE !== 'production' && console.timeEnd('font')

      // Set default language
      const lang =
        (initialRoute.params.lang as Language) || getLanguageDefault(country)

      // Plugins
      const countryPlugin = createCountry(country)
      const axiosPlugin = createAxios(
        { acceptLanguage: lang },
        { legacy: true }
      )
      const head = createHead()
      const pinia = createPinia()
      const i18n = initI18n(country)

      app.use(head)
      app.use(i18n.instance)
      app.use(pinia)
      app.use(countryPlugin)
      app.use(axiosPlugin)
      app.use(svgSpritePlugin, {
        url: '',
      })

      // Components
      app.component(ClientOnly.name || 'ClientOnly', ClientOnly)
      app.component('Action', Action)
      app.component('HtmlText', HtmlText)
      app.component('Lazy', Lazy)
      app.component('GPlayer', GPlayer)

      // Directives
      app.directive('outside', outside)
      app.directive('src', src)

      // Other…
      app.provide(ContentKey, readonly(contentRef))

      app.runWithContext(() => {
        createGuards(context, { i18n, pinia })
      })

      if (import.meta.env.SSR) {
        // This will be stringified and set to window.__INITIAL_STATE__
        initialState.pinia = pinia.state.value
      } else {
        // On the client side, we restore the state.
        // This should be done before calling any useStore() function on client side !!!
        pinia.state.value = initialState.pinia

        const chrome = useChromeStore(pinia)
        const navigation = useNavigationStore(pinia)

        // Set locales on the client side
        i18n.setLocale(lang, chrome.i18n, false)
        navigation.referrer = document.referrer
      }

      return { head }
    },
  }
)
