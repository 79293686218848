import type { Country, Language } from '@/types'

/**
 * Languages
 */
const languageAll = ['fr', 'nl', 'en'] as const
const languageAvailable: Record<Country, Language[]> = {
  be: ['fr', 'nl'],
  lu: ['en', 'fr'],
}
const languageDefault: Record<Country, Language> = {
  be: 'fr',
  lu: 'en',
}

const getLanguageAvailable = (c: Country) => languageAvailable[c]
const getLanguageDefault = (c: Country) => languageDefault[c]

export { languageAll, getLanguageAvailable, getLanguageDefault }
